import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import processRows from "./processRows";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import SongSearch from "./SongSearch";

const MatchingTool = (props) => {
  let [rows, setRows] = useState(null);
  let [current, setCurrent] = useState(0);

  const getData = async () => {
    const response = await axios.post(
      apiRoot,
      {
        query: `
        query getMatchData($id: Int!) {
          admin {
            clientStatementPeriod(id: $id) {
              unmatchedRows {
                id
                society
                parse
              }
            }
          }
        }`,
        variables: { id: Number(props.match.params.id) },
      },
      { withCredentials: true }
    );
    setRows(
      processRows(response.data.data.admin.clientStatementPeriod.unmatchedRows)
    );
  };

  const match = async () => {
    await axios.post(
      apiRoot,
      {
        query: `
        mutation match($work: Int!, $row: [Int!]!) {
          admin {
            manualMatchRows(row_ids: $row, work_id: $work)
          }
        }`,
        variables: { work: rows[current].work, row: rows[current].ids },
      },
      { withCredentials: true }
    );
    setCurrent(current + 1);
  };

  // eslint-disable-next-line
  useEffect(() => getData(), [props.match.params.id]);

  return (
    <Box m={2}>
      <Typography variant="h4">Matching Tool</Typography>
      {(!rows || rows.length === 0) && (
        <Typography variant="body1">No rows to match</Typography>
      )}
      {rows && rows.length > 0 && (
        <Paper
          style={{
            padding: "20px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">TOTAL</Typography>
              <Typography variant="h4">{rows.length}</Typography>
            </div>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">LEFT</Typography>
              <Typography variant="h4">
                {rows.filter((i) => !i.work).length}
              </Typography>
            </div>
            <div style={{ marginRight: "40px" }}>
              <Typography variant="overline">DONE</Typography>
              <Typography variant="h4">
                {rows.filter((i) => i.work).length}
              </Typography>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {current < rows.length - 1 && (
              <div style={{ marginRight: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCurrent(current + 1)}
                >
                  Skip
                </Button>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={rows && rows.length > current && !rows[current].work}
              onClick={match}
            >
              Submit + Next
            </Button>
          </div>
        </Paper>
      )}
      {rows && rows.length > current && (
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <Paper style={{ padding: "20px" }}>
              {typeof rows[current] === "object" && (
                <div>
                  <Table>
                    <TableBody>
                      {Object.keys(rows[current]).map((key) => (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            <strong>{key}</strong>
                          </TableCell>
                          <TableCell
                            style={{ wordWrap: "break-word", maxWidth: "30vw" }}
                          >
                            {key === "ids"
                              ? JSON.stringify(rows[current][key])
                              : typeof rows[current][key] === "object"
                              ? "[object]"
                              : rows[current][key]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <SongSearch
              work={rows[current].work}
              title={rows[current].title}
              select={(id) =>
                setRows(
                  rows.map((i) =>
                    i.work_id === rows[current].work_id
                      ? {
                          ...rows[current],
                          work: id === rows[current].work ? null : id,
                        }
                      : i
                  )
                )
              }
            />
          </Grid>
        </Grid>
      )}
      {rows && rows.length === current && (
        <Typography variant="body1">No rows left to match</Typography>
      )}
    </Box>
  );
};

export default MatchingTool;
